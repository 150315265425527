/* eslint-disable react/jsx-no-target-blank */
import React from "react";
//import { manualesJuego } from "../instalaciones";

const ManualJuego = ({ manualesJuego }) => {
  return (
    <a
      style={
        ["13", "43", "11", "41", "23", "53", "20", "50", "48", "18"].includes(
          window.parent.getCodigoOrganizacion()
        )
          ? { cursor: "pointer", color: "white" }
          : { cursor: "pointer", color: "black" }
      }
      className="manual-juego manual-juego-desk"
      target="_blank"
      href={manualesJuego}
    >
      <span
        className="iconify"
        data-icon="bx:bx-help-circle"
        data-inline="false"
        data-width="25px"
      ></span>
    </a>
  );
};
export default ManualJuego;
