import React from "react";

const TerminosCondiciones = () => {
  return (
    <div className="p-1 mx-auto">
      <h5 className="text-center mb-3">BASES Y CONDICIONES DE USO</h5>

      <p className="text-small text-justify">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id
        bibendum ipsum. Cras ultrices ligula tortor, dictum aliquam velit porta
        id. Maecenas cursus metus sed quam posuere, in sagittis nunc eleifend.
        Mauris quis elit nunc.
        <br></br>
        Donec at sem enim. Pellentesque vel mollis diam, nec pellentesque arcu.
        Fusce quis gravida dolor, sit amet egestas sapien. Mauris at accumsan
        erat. Integer tellus sem, convallis ut tellus a, rutrum commodo nisi.
        <br></br>
        In in dapibus lorem. Phasellus at consectetur urna, ac imperdiet justo.
        Aenean dapibus feugiat neque ut consequat. Curabitur quis nunc quis urna
        fringilla mollis. Aenean rutrum condimentum cursus. Pellentesque
        ultricies finibus tristique. Phasellus at lectus tellus. Aliquam maximus
        felis vel urna auctor, eu congue odio auctor. Donec pretium fringilla
        risus.
      </p>

      <h5 className="text-center mb-3 mt-3">1 - DEL VÍNCULO Y SUS EFECTOS</h5>
      <p className="text-small text-justify">
        Integer lacinia tempor justo, sit amet condimentum lectus fermentum
        maximus. Aenean finibus nisi neque, non hendrerit felis lacinia ac.
        Etiam feugiat lobortis arcu a pretium. Etiam accumsan est eget risus
        elementum, sed tempor turpis sodales. In in neque enim. Donec non mollis
        purus. Cras consectetur ante velit, pulvinar tincidunt risus congue nec.
        Maecenas quis iaculis velit. Fusce nec ligula eget sem congue sagittis.
        Maecenas fermentum rhoncus lacus eu dapibus. Class aptent taciti
        sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        Mauris nec justo vehicula, tristique nibh vel, vehicula enim. Fusce
        imperdiet accumsan sem nec pretium. Morbi pharetra felis id nisi
        volutpat pulvinar.
      </p>
      <h5 className="text-center mb-3 mt-3">2 - DE LA PROPIEDAD INTELECTUAL</h5>
      <p className="text-small text-justify">
        Integer lacinia tempor justo, sit amet condimentum lectus fermentum
        maximus. Aenean finibus nisi neque, non hendrerit felis lacinia ac.
        Etiam feugiat lobortis arcu a pretium. Etiam accumsan est eget risus
        elementum, sed tempor turpis sodales. In in neque enim. Donec non mollis
        purus. Cras consectetur ante velit, pulvinar tincidunt risus congue nec.
        Maecenas quis iaculis velit. Fusce nec ligula eget sem congue sagittis.
        Maecenas fermentum rhoncus lacus eu dapibus. Class aptent taciti
        sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        Mauris nec justo vehicula, tristique nibh vel, vehicula enim. Fusce
        imperdiet accumsan sem nec pretium. Morbi pharetra felis id nisi
        volutpat pulvinar.
      </p>
      <p className="text-small text-justify">
        Fusce sit amet posuere nunc. Sed bibendum facilisis justo, a cursus arcu
        condimentum aliquam. Suspendisse vehicula ornare nibh ac pretium. Mauris
        ipsum justo, gravida et libero sit amet, molestie laoreet turpis. Nullam
        vestibulum lacinia augue, sed lacinia ante consequat quis. Proin gravida
        nulla eget purus rutrum vehicula. Duis vestibulum faucibus nulla, id
        commodo turpis pulvinar eget. Maecenas accumsan odio nec leo consectetur
        imperdiet. Fusce a pharetra justo. Phasellus euismod laoreet feugiat.
        Etiam vitae diam est. Morbi auctor sem ut sollicitudin elementum.
      </p>
    </div>
  );
};

export default TerminosCondiciones;
