import React, { useEffect, useState } from "react";
import $ from "jquery";
import StarRatingComponent from "react-star-rating-component";

const Calificar = (props) => {
  const [rating, setrating] = useState(0);
  const [comentario, setcomentario] = useState("");
  const [nps, setnps] = useState(undefined);
  const [leyenda, setleyenda] = useState(undefined);
  useEffect(() => {
    setnps(props.nps);
  }, []);

  const onStarClick = (nextValue, prevValue, name) => {
    setrating(nextValue);
    if (nextValue === 1) {
      setleyenda("No me gusta");
    } else if (nextValue === 2) {
      setleyenda("Regular");
    } else if (nextValue === 3) {
      setleyenda("Buena");
    } else if (nextValue === 4) {
      setleyenda("Muy Buena");
    } else if (nextValue === 5) {
      setleyenda("Excelente");
    }
  };

  const handleCalificar = () => {
    if (rating < 1 && comentario !== "") {
      $.alert({
        title: "<h4>Ups. </h4>",
        content:
          '<div className="text-center pb-2 mt-2">Por favor ingrese una calificación.</div>',
        buttons: {
          continuar: function () {},
        },
      });
    } else if (rating < 1 && comentario === "") {
      $("#rateModal").modal("hide");
    } else if (rating > 0) {
      enviarNps();
    }
  };

  const enviarNps = () => {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_ENVIAR_NPS;

    let parametros = {
      puntaje: rating,
      comentario: comentario,
      modulo: props.estadoPlataforma.nps.modulo,
      moduloVersion: props.estadoPlataforma.nps.version,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          $("#rateModal").modal("hide");
          $("#comment").val("");
          setrating(0);
          setcomentario("");
          setleyenda("");
        } else if (json.status === "error") {
          $("#rateModal").modal("hide");
          $("#comment").val("");
          setrating(0);
          setcomentario("");
          setleyenda("");
        }
      })
      .catch((error) => {
        $("#rateModal").modal("hide");
        $("#comment").val("");
        setrating(0);
        setcomentario("");
        setleyenda("");
      });
  };

  return (
    <div>
      <div
        className="modal fade"
        id="rateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="rateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ marginTop: "5%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="rateModalLabel">
                {props.estadoPlataforma.titulo}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body text-center">
              <div id="rateStar">
                <StarRatingComponent
                  name="rate1"
                  starCount={5}
                  value={rating}
                  onStarClick={onStarClick}
                />
                <h4>{leyenda}</h4>
              </div>
            </div>

            <div className="w-90 mx-auto">
              <h6 className="modal-title text-center" id="commentModalLabel">
                Dejanos tus comentarios
              </h6>

              <div className="form-group">
                <textarea
                  id="comment"
                  className="form-control"
                  onChange={(e) => setcomentario(e.target.value)}
                  maxLength="200"
                  rows="3"
                ></textarea>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info"
                onClick={handleCalificar}
              >
                Calificar!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calificar;
