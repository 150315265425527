import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import indiecita from "../../src/img/Indiecita.png";
import cornetas from "../../src/img/top_con_cornetas.png";
import axios from "axios";
import cerrar from "../../src/img/cerrar.png";
import signoDePregunta from "../../src/img/signodepregunta.png";
import $ from "jquery";

const Trivia = ({
  viewTrivia,
  setviewTrivia,
  viewConfirmacion,
  setviewConfirmacion,
  documento,
  token,
  size,
  viewBases,
  setviewBases,
}) => {
  const [qatarFondo, setqatarFondo] = useState("#ffffff");
  const [qatarLetra, setqatarLetra] = useState("#217bbe");
  const [canadaFondo, setcanadaFondo] = useState("#ffffff");
  const [canadaLetra, setcanadaLetra] = useState("#217bbe");
  const [argentinaFondo, setargentinaFondo] = useState("#ffffff");
  const [argentinaLetra, setargentinaLetra] = useState("#217bbe");
  const [brasilFondo, setbrasilFondo] = useState("#ffffff");
  const [brasilLetra, setbrasilLetra] = useState("#217bbe");
  const [ciaoFondo, setciaoFondo] = useState("#ffffff");
  const [ciaoLetra, setciaoLetra] = useState("#217bbe");
  const [laebbFondo, setlaebbFondo] = useState("#ffffff");
  const [laebbLetra, setlaebbLetra] = useState("#217bbe");
  const [triviaRespuesta1, settriviaRespuesta1] = useState("");
  const [triviaRespuesta2, settriviaRespuesta2] = useState("");
  const [triviaRespuesta3, settriviaRespuesta3] = useState("");
  const [checkBases, setcheckBases] = useState(false);

  const alertaError = (mensaje) => {
    $.confirm({
      title: '<b className="op-text">Trivia</b>',
      backgroundDismiss: true,
      columnClass: "medium",
      animation: "zoom",
      closeIcon: true,
      closeAnimation: "scale",
      content: `<h5 className="text-center mt-4 mb-2">
          ${mensaje}
					 </h5>`,
      buttons: {
        Si: {
          text: "Aceptar",
          action: function () {},
        },
      },
    });
  };

  const handleClickParticipar = () => {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_INIT_TRIVIA;

    if (
      triviaRespuesta1 === "" ||
      triviaRespuesta2 === "" ||
      triviaRespuesta3 === ""
    ) {
      alertaError("Por favor, complete todas las respuestas");
    } else {
      axios
        .post(
          url,
          {
            documentoUsuario: documento,
            respuesta1: triviaRespuesta1,
            respuesta2: triviaRespuesta2,
            respuesta3: triviaRespuesta3,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            cerrarTrivia();
            setviewConfirmacion(true);
          }
          return res.data;
        })
        .catch((err) => {
          console.error(err);
          alertaError("Ha habido un error intentelo nuevamente");
        });
    }
  };

  const clickCheckbox = () => {
    setcheckBases(!checkBases);
  };

  const clickBoton = (valor) => {
    switch (valor) {
      case "botonQatar":
        setqatarFondo("#0c4fa3");
        setqatarLetra("#ffffff");
        setcanadaFondo("#ffffff");
        setcanadaLetra("#217bbe");
        settriviaRespuesta1("Qatar");
        break;
      case "botonCanada":
        setcanadaFondo("#0c4fa3");
        setcanadaLetra("#ffffff");
        setqatarFondo("#ffffff");
        setqatarLetra("#217bbe");
        settriviaRespuesta1("Canada");
        break;
      case "botonArgentina":
        setargentinaFondo("#0c4fa3");
        setargentinaLetra("#ffffff");
        setbrasilFondo("#ffffff");
        setbrasilLetra("#217bbe");
        settriviaRespuesta2("Argentina");
        break;
      case "botonBrasil":
        setbrasilFondo("#0c4fa3");
        setbrasilLetra("#ffffff");
        setargentinaFondo("#ffffff");
        setargentinaLetra("#217bbe");
        settriviaRespuesta2("Brasil");
        break;
      case "botonCiao":
        setciaoFondo("#0c4fa3");
        setciaoLetra("#ffffff");
        setlaebbFondo("#ffffff");
        setlaebbLetra("#217bbe");
        settriviaRespuesta3("Ciao");
        break;
      case "botonLaebb":
        setlaebbFondo("#0c4fa3");
        setlaebbLetra("#ffffff");
        setciaoFondo("#ffffff");
        setciaoLetra("#217bbe");
        settriviaRespuesta3("La'ebb");
        break;
      case "cerrar":
        settriviaRespuesta1("");
        settriviaRespuesta2("");
        settriviaRespuesta3("");
        setviewTrivia(false);
        break;
      case "bases":
        setviewBases(true);
        setviewTrivia(false);
        settriviaRespuesta1("");
        settriviaRespuesta2("");
        settriviaRespuesta3("");
        break;
      default:
    }
  };

  const cerrarTrivia = () => {
    setviewTrivia(false);
  };

  return (
    <DivGralCard>
      <div className="container">
        <div className="row">
          <div className="col">
            <DivCard
              size={size}
              className="card text-center animate__animated animate__fadeInDownBig"
            >
              <div className="card-header" style={{ color: "#ffffff" }}>
                <DivCerrar className="btn" id="btnCerrar">
                  <img
                    src={cerrar}
                    alt="cerrar"
                    id="cerrar"
                    onClick={(e) => clickBoton(e.target.id)}
                  />
                </DivCerrar>
                <img src={cornetas} alt="cornetas" style={{ width: "43vh" }} />
              </div>
              <div className="card-body" style={{ color: "#ffffff" }}>
                <ImgIndiecita src={indiecita} alt="indiecita" />
                <div className="container">
                  <div className="row mb-1">
                    <div className="col-8">¿Dónde se juega el mundial?</div>
                  </div>
                  <div className="row mb-1">
                    <BotonTrivia
                      fondo={canadaFondo}
                      fontColor={canadaLetra}
                      type="button"
                      className="btn mr-1"
                      id="botonCanada"
                      onClick={(e) => clickBoton(e.target.id)}
                    >
                      Cánada
                    </BotonTrivia>
                    <BotonTrivia
                      fondo={qatarFondo}
                      fontColor={qatarLetra}
                      type="button"
                      className="btn"
                      id="botonQatar"
                      onClick={(e) => clickBoton(e.target.id)}
                    >
                      Qatar
                    </BotonTrivia>
                  </div>
                  <div className="row mb-1">
                    <div className="col-8">
                      ¿Quién pensás que gana el mundial?
                    </div>
                  </div>
                  <div className="row mb-1">
                    <BotonTrivia
                      fondo={argentinaFondo}
                      fontColor={argentinaLetra}
                      type="button"
                      className="btn mr-1"
                      id="botonArgentina"
                      onClick={(e) => clickBoton(e.target.id)}
                    >
                      Argentina
                    </BotonTrivia>
                    <BotonTrivia
                      fondo={brasilFondo}
                      fontColor={brasilLetra}
                      type="button"
                      className="btn"
                      id="botonBrasil"
                      onClick={(e) => clickBoton(e.target.id)}
                    >
                      Brasil
                    </BotonTrivia>
                  </div>
                  <div className="row mb-1">
                    <div className="col-8">
                      ¿Cómo se llama la mascota del mundial?
                    </div>
                  </div>
                  <div className="row mb-1 pl-3">
                    <BotonTrivia
                      fondo={ciaoFondo}
                      fontColor={ciaoLetra}
                      type="button"
                      className="btn mr-1"
                      id="botonCiao"
                      onClick={(e) => clickBoton(e.target.id)}
                    >
                      Ciao
                    </BotonTrivia>
                    <BotonTrivia
                      fondo={laebbFondo}
                      fontColor={laebbLetra}
                      type="button"
                      className="btn"
                      id="botonLaebb"
                      onClick={(e) => clickBoton(e.target.id)}
                    >
                      La'ebb
                    </BotonTrivia>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="checkBases"
                      onClick={() => clickCheckbox()}
                      style={{ marginLeft: "-8.25rem" }}
                    />
                    <div>
                      <label
                        className="form-check-label"
                        for="checkBases"
                        id="bases"
                        onClick={(e) => clickBoton(e.target.id)}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "darkmagenta",
                        }}
                      >
                        Acepta bases y condiciones
                      </label>
                    </div>
                  </div>
                </div>

                <ButtonParticipar
                  onClick={() => handleClickParticipar()}
                  className="btn btn-light"
                  style={{ color: "#01c160" }}
                  id="botonParticipar"
                  disabled={!checkBases}
                >
                  PARTICIPAR
                </ButtonParticipar>
              </div>
            </DivCard>
          </div>
        </div>
      </div>
    </DivGralCard>
  );
};

export default Trivia;

const DivCard = styled.div`
  top: ${(props) => (props.size <= 991 ? "6%" : "6%")};
  min-width: 40vh;
  min-height: 71vh;
  max-width: 61vh;
  background-color: #217bbe;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
`;
const ButtonParticipar = styled.button`
  margin-top: 4vh;
  min-width: 7em;
`;
const DivGralCard = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #0000005e;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3 !important;
`;

const BotonTrivia = styled.button`
  background-color: #ffffff;
  width: auto;
  font-size: 2vh;
  background-color: ${(props) => props.fondo};
  color: ${(props) => props.fontColor};
`;
const ImgIndiecita = styled.img`
  z-index: -2 !important;
  position: fixed;
  height: 68%;
  right: -3vh;
`;

const DivCerrar = styled.div`
  position: fixed;
  right: 1vh;
`;
