import React from "react";

const preguntasFrecuentes = () => {
  return (
    <div className=" mx-auto" id="accordionQA">
      <div className="card">
        <div
          className={
            "card-header bg-info bg-prov-" +
            process.env.REACT_APP_COD_ORGANIZACION
          }
          id="QA1"
        >
          <h5 className="mb-0">
            <div
              className="text-light text-center"
              data-toggle="collapse"
              data-target="#collapseQA1"
              aria-expanded="false"
              aria-controls="collapseQA1"
            >
              Sobre la Registración
            </div>
          </h5>
        </div>

        <div
          id="collapseQA1"
          className="collapse show"
          aria-labelledby="QA1"
          data-parent="#accordionQA"
        >
          <div className="card-body">
            <legend className="text-center mb-3">¿Cómo me registro?</legend>
            <p className="text-small text-justify">
              Podes registrarte desde tu celular pinchando la opción
              "Registrarse" en la pantalla de Inicio.
            </p>

            <legend className="text-center mb-3">
              ¿Por qué tengo que registrarme?
            </legend>
            <p className="text-small text-justify">
              Al registrarte podrás utilizar todos los productos que se ofrecen
              en la página. Con tu registro abrís una cuenta donde administrarás
              tus datos personales y tus apuestas a través de una billetera
              virtual. Podrás realizar tus apuestas cuando hayas realizado un
              depósito en tu cuenta.{" "}
            </p>

            <legend className="text-center mb-3">
              ¿Por qué dar mis datos?
            </legend>
            <p className="text-small text-justify">
              Es necesario que tus datos sean correctos para poder utilizar los
              productos. Debes ser mayor de 18 años y tu correo se utiliza para
              mantener tu cuenta activa en caso de olvido de contraseña o
              cualquier necesidad de comunicarnos.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="card">
        <div
          className={
            "card-header bg-info bg-prov-" +
            process.env.REACT_APP_COD_ORGANIZACION
          }
          id="QA2"
        >
          <h5 className="mb-0">
            <div
              className="text-light text-center"
              data-toggle="collapse"
              data-target="#collapseQA2"
              aria-expanded="false"
              aria-controls="collapseQA2"
            >
              Sobre tu Cuenta
            </div>
          </h5>
        </div>

        <div
          id="collapseQA2"
          className="collapse"
          aria-labelledby="QA2"
          data-parent="#accordionQA"
        >
          <div className="card-body">
            <legend className="text-center mb-3">
              ¿Cómo administro mi cuenta?
            </legend>
            <div className="text-small text-justify mb-3">
              Administrar tu cuenta es muy fácil.
              <br></br>
              Puedes visualizar tus datos a través de la opción "Mi cuenta" o
              "Usuario" y realizar las siguientes funciones:
              <br></br>
              <br></br>
              <ul>
                <li>
                  Cambiar tu agencia donde podrás realizar retiros en efectivo.
                </li>
                <li>
                  Activar o desactivar las notificaciones de premios enviados
                  por correo electrónico.
                </li>
                <li>
                  Cambiar tu clave de acceso, seleccionando la opción “Modificar
                  contraseña”.
                </li>
              </ul>
              Para consultar tu saldo y movimientos de tu cuenta, selecciona la
              opción "Billetera".
            </div>

            <legend className="text-center mb-3">Olvide mi contraseña</legend>
            <p className="text-small text-justify">
              Podrá generar una nueva contraseña al ingresar a la aplicación
              seleccionando la opción “¿Olvidaste tu contraseña?”. Se le enviará
              un correo con un LINK para que pueda generar su nueva clave.
            </p>
          </div>
        </div>
      </div>

      <div className="card">
        <div
          className={
            "card-header bg-info bg-prov-" +
            process.env.REACT_APP_COD_ORGANIZACION
          }
          id="QA3"
        >
          <h5 className="mb-0">
            <div
              className="text-light text-center"
              data-toggle="collapse"
              data-target="#collapseQA3"
              aria-expanded="false"
              aria-controls="collapseQA3"
            >
              Depósitos y Retiros
            </div>
          </h5>
        </div>

        <div
          id="collapseQA3"
          className="collapse"
          aria-labelledby="QA3"
          data-parent="#accordionQA"
        >
          <div className="card-body">
            <legend className="text-center mb-3">
              ¿Cómo realizo depósitos y retiros en mi cuenta? 
            </legend>
            En el menú de ayuda selecciona la opción "Medios de Pago" para ver
            la explicación detallada acerca de los depósitos y retiros en
            efectivo.
          </div>
        </div>
      </div>
    </div>
  );
};

export default preguntasFrecuentes;
