import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DivGeneral } from "./consultaBilletera";
import RetiroDinero from "./retiroDinero";
import IngresoDinero from "./ingresoDinero";
import styled from "styled-components";
import { estilosProv } from "../configProvincias";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  DivColRetiro,
  DivRow,
  H4Retiro,
} from "../tools/identificadorQRCliente/PaginaSolicitudRetiro";

const IdentificadorQRCliente = ({
  estadoPlataforma,
  mensajeErrorWS,
  mensajeErrorGeneral,
  cerrarSesion,
  history,
  logCurrentPage,
}) => {
  const { ingresoretiro } = useParams();
  const [active, setactive] = useState(0);
  const color = process.env.REACT_APP_MANIFEST_COLOR;

  useEffect(() => {
    logCurrentPage("retirar");
  }, []);

  useEffect(() => {}, [active]);

  return (
    <DivGeneral className="pageFlow ">
      <DivRow className="row justify-content-center">
        <DivColRetiro className="col-12">
          <h3>
            <span
              className="iconify"
              data-icon="fluent:wallet-credit-card-28-filled"
              data-inline="false"
              data-width="60px"
              color={color}
            />
          </h3>
        </DivColRetiro>
      </DivRow>
      <div className="row justify-content-center text-center">
        <h3>
          <div className="col-12 mt-4">
            <div>Billetera</div>
          </div>
        </h3>
      </div>
      {process.env.REACT_APP_ENABLE_INGRESO_DINERO && (
        <ul className="nav justify-content-center">
          <li className="nav-item">
            <Link to="/identificadorQRCliente/0">
              <BotonTab
                color={color}
                active={ingresoretiro == 0}
                onClick={() => setactive(0)}
                className="nav-link"
                aria-current="page"
              >
                Ingresar Dinero
              </BotonTab>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/identificadorQRCliente/1">
              <BotonTab
                color={color}
                active={ingresoretiro == 1}
                className="nav-link"
                onClick={() => setactive(1)}
              >
                Retirar Dinero
              </BotonTab>
            </Link>
          </li>
        </ul>
      )}
      {ingresoretiro == 0 ? (
        <IngresoDinero
          estadoPlataforma={estadoPlataforma}
          mensajeErrorWS={mensajeErrorWS}
          mensajeErrorGeneral={mensajeErrorGeneral}
          cerrarSesion={cerrarSesion}
          history={history}
          logCurrentPage={logCurrentPage}
        />
      ) : (
        <RetiroDinero
          estadoPlataforma={estadoPlataforma}
          mensajeErrorWS={mensajeErrorWS}
          mensajeErrorGeneral={mensajeErrorGeneral}
          cerrarSesion={cerrarSesion}
          history={history}
          logCurrentPage={logCurrentPage}
        />
      )}
    </DivGeneral>
  );
};

export default IdentificadorQRCliente;

const BotonTab = styled.button`
  border: none;
  border-bottom: solid;
  ${(props) =>
    props.active
      ? "border-bottom-color:" + props.color + ";"
      : "border-bottom-color: transparent"};

  background-color: transparent;

  &:hover {
    background-color: #ebebeb;
  }
`;
