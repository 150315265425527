import React from "react";
import { Animated } from "react-animated-css";
import { withRouter, useHistory } from "react-router-dom";

const CerrarSesion = (props) => {
  const history = useHistory();

  const clickCerrarSesion = () => {
    cerrarSesion();
  };

  const cerrarSesion = () => {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_CERRAR_SESION;
    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        return respPromise.json();
      })
      .then((json) => {
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");

        if (json.status === "ok") {
          props.cerrarSesion();
          history.push("/");
        } else if (json.status === "error") {
          props.cerrarSesion();
          history.push("/");
        }
      })
      .catch((error) => {
        props.cerrarSesion();
        history.push("/");
        //this.props.mensajeErrorGeneral();
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
      });
  };

  return (
    <Animated animationIn="fadeIn" isVisible={true}>
      <div className="text-center">
        <h5 className="mb-3 mt-2">¿Desea cerrar su sesión?</h5>
        <hr className="mt-0" />

        <button
          className="btn btn-danger mr-2"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.volverAPlataforma}
        >
          <div>Cancelar</div>
        </button>

        <button
          className="btn btn-info ml-2"
          data-dismiss="modal"
          aria-label="Close"
          onClick={clickCerrarSesion}
        >
          Aceptar
        </button>
      </div>
    </Animated>
  );
};

export default withRouter(CerrarSesion);

//('25d939e4-86af-4b89-89f3-bae66f060f8e','2c6a6cb1-433c-405f-8a2a-13c1908a624b','39d9caed-5f3b-46d6-ba65-47f4adc45e76','44f21d67-0b58-46bb-a18d-7c7a89413f6d','4830525b-4400-46b2-9d5c-083a7c2ece6b','58811500-4c4c-4f1c-abef-1e3a6a8acd18','5898a269-bae6-4850-8f3f-911016cc3cf6','7f1b82e1-84a0-4d52-89e6-1ab7f72054a0','881f88c0-4cd9-4180-8772-44eb9ad74ca4','8c6ca09b-224c-48a8-a5bd-08841f1fe19e','992cec88-d0e6-48b6-a512-b8838cb10581','9bdf1733-2d6a-4736-9a0c-ed437f541491','9f65ae61-6079-4799-9e78-2d67e2e3ff98','be431001-443c-4bba-8743-b04063a30502','c326e98e-e3d7-4197-aa92-b2ea06448d7c','cb578ecc-3669-4a21-b3e3-d35cbd255817','cbc96b00-e324-42ab-8aa9-52018558b966','d4efd11a-a7ce-4612-abdc-6725a2d60588','d85bb45f-c886-41e9-acd1-b9cf76ac64b9','f3310a57-6572-48d8-bbef-ec04607fc919','f7791f70-a5ba-41bd-9081-a3540c682b90','fefbbbe1-8de6-442a-98d4-c8a9215f0041')
