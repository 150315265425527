import React, { useEffect } from "react";

const ModalAgencias = ({
  listaLocalidades,
  includeSubAg,
  hashAgencias,
  handleAgencia,
  handleSubAgencia,
  seleccionaTuAgencia,
}) => {
  return (
    <div
      className="modal fade"
      id="agenciaModal"
      role="dialog"
      aria-labelledby="agenciaModalLabel"
      aria-hidden="true"
      style={
        navigator.vendor.toLocaleLowerCase().includes("apple")
          ? { overflow: "auto", top: "14vh", height: "72vh" }
          : { overflow: "auto" }
      }
    >
      <div className="modal-dialog w-100 m-0" role="document">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              position: "absolute",
              zIndex: "3",
              backgroundColor: "white",
              top: "0",
              width: "100%",
            }}
          >
            <h6 className="modal-title op-text" id="agenciaModalLabel">
              Seleccione <span className="m-bold">localidad</span> <br /> y{" "}
              <span className="m-bold">agencia</span> más cercana
            </h6>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div
            style={{ marginTop: "25%" }}
            className="modal-body text-center mb-2"
          >
            {listaLocalidades.map((value, index) => (
              <div key={index}>
                <div
                  className="border p-1 mb-1 text-left"
                  data-toggle="collapse"
                  href={"#opt" + index}
                  role="button"
                  aria-expanded="false"
                  aria-controls={"#opt" + index}
                >
                  <span style={{ fontSize: "12px" }} className="op-text">
                    Localidad
                  </span>
                  <br />
                  <span className="m-bold">{value.localidad}</span>
                </div>

                {!includeSubAg &&
                  hashAgencias[value.localidad].map((agencia) => (
                    <div key={index} className="collapse" id={"opt" + index}>
                      <div
                        onClick={() => {
                          handleAgencia(agencia);
                        }}
                        data-dismiss="modal"
                        className="text-left p-2 mb-1 border-bottom"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <span className="m-bold">
                          Agencia n° {agencia.descripcion}
                        </span>
                        <br />
                        <span style={{ fontSize: "12px" }} className="op-text">
                          {agencia.direccion}
                        </span>
                        <span
                          style={{
                            display: "flex",
                            float: "right",
                            marginTop: "-3%",
                          }}
                          className="iconify"
                          data-icon="dashicons:arrow-right-alt2"
                          data-inline="false"
                          data-width="20px"
                        ></span>
                      </div>
                    </div>
                  ))}

                {includeSubAg &&
                  hashAgencias[value.localidad].map((agencia) => (
                    <div key={index} className="collapse" id={"opt" + index}>
                      <div
                        onClick={() => {
                          handleSubAgencia(agencia);
                        }}
                        className="text-left p-2 mb-1 border-bottom"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <span className="m-bold">
                          Agencia n° {agencia.descripcion}
                        </span>
                        <br />
                        <span style={{ fontSize: "12px" }} className="op-text">
                          {agencia.direccion}
                        </span>
                        <span
                          style={{
                            display: "flex",
                            float: "right",
                            marginTop: "-3%",
                          }}
                          className="iconify"
                          data-icon="dashicons:arrow-right-alt2"
                          data-inline="false"
                          data-width="20px"
                        ></span>
                      </div>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAgencias;
