import React from "react";
import { Button, FormGroup, Label, Input } from "reactstrap";
import $ from "jquery";
import "jquery-confirm";
import { Animated } from "react-animated-css";
import { withRouter } from "react-router-dom";

class OlvideClave extends React.Component {
  constructor(props) {
    super(props);
    this.validateFormNroDocu = this.validateFormNroDocu.bind(this);
    this.olvideMiClave = this.olvideMiClave.bind(this);

    this.state = {
      nroDocu: "",
      correo: "",
      cargando: "INICIO",
      errores: [],
      errors: {
        nroDocu: "",
      },
    };
  }

  handleChangeNroDocu = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "nroDocu":
        errors.nroDocu =
          value.length === 0 ? "Ingresar el Nro. de Documento o Correo" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, nroDocu: value });
    this.props.generoNuevaClave(false);
  };

  notTab(event) {
    event.preventDefault();
    let tab = event.charCode != 32;
    return tab;
  }

  validateFormNroDocu() {
    let valid = true;

    let errors = this.state.errors;

    if (this.state.nroDocu === "") {
      valid = false;
      errors.nroDocu = "Ingresar el número de documento o correo electrónico";
      $("#nroDocu").focus();
    }

    return valid;
  }

  olvideMiClave() {
    this.props.handleRemember("erase");
    this.props.uncheck();
    this.setState({ cargando: "EJECUTANDO" });
    if (this.validateFormNroDocu() === false) {
      this.setState({});
      return;
    }

    let parametros = {
      codigoOrganizacion: process.env.REACT_APP_COD_ORGANIZACION_FETCH,
      username: this.state.nroDocu,
    };

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_OLVIDE_CLAVE;

    let statusCode = "";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          this.props.cerrarSesion();
          this.props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          this.props.generoNuevaClave(true);
          this.setState({ cargando: "FIN" });
          this.setState({
            errores: [],
            correo: json.mail,
          });
        } else if (json.status === "error") {
          this.props.generoNuevaClave(false);
          this.setState({ cargando: "FIN" });
          this.setState({
            errores: json.errores,
          });
          this.props.mensajeErrorWS("Olvidé mi clave", json.errores);
        }
      })
      .catch((error) => {
        this.props.mensajeErrorGeneral();
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
        this.setState({ cargando: "FIN" });
      });
  }

  render() {
    return (
      <div className="mt-2 p-2">
        {this.props.nroDocuEnviado === false && (
          <div>
            <p className="font-12 op-text">
              Se enviará un correo a su casilla con las instrucciones para
              generar su nueva clave.
            </p>
            <FormGroup>
              <Label for="nroDocu">Nro. Documento o Correo </Label>
              <Input
                type="text"
                name="nroDocu"
                className="nroDocuOlvide"
                id="nroDocu"
                placeholder=""
                autoComplete="off"
                onChange={this.handleChangeNroDocu}
              />
            </FormGroup>
            <span style={{ color: "red", position: "relative", top: "-10px" }}>
              {this.state.errors.nroDocu}
            </span>

            <div className="text-center">
              <button
                className={
                  "btn mt-2 mb-3 bg-prov-" +
                  process.env.REACT_APP_COD_ORGANIZACION_FETCH
                }
                onClick={this.olvideMiClave}
              >
                {this.state.cargando === "EJECUTANDO" && (
                  <div className="animate-flicker">
                    <b>Generando ...</b>
                  </div>
                )}

                {(this.state.cargando === "INICIO" ||
                  this.state.cargando === "FIN") && <span>Generar</span>}
              </button>
            </div>
          </div>
        )}

        {this.props.nroDocuEnviado === true && (
          <Animated animationIn="fadeIn">
            <div className="w-90 mx-auto text-center op-text">
              <p>
                Se ha enviado un correo a la casilla {this.state.correo}, que
                contiene las instrucciones para generar una nueva clave.{" "}
              </p>
            </div>
          </Animated>
        )}
      </div>
    );
  }
}

export default withRouter(OlvideClave);
