import React from "react";
import { useEffect } from "react";
import { Animated } from "react-animated-css";
import { useHistory } from "react-router-dom";

const ActualizarVersion = (props) => {
  const history = useHistory();

  const actualizar = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    history.push("/");
    props.actualizarVersion();
    //window.location.reload(true);
  };

  useEffect(() => {
    props.actuVersionSiCorresponde(props);
  }, []);

  return (
    <Animated animationIn="slideInRight">
      <div className="text-center mt-4 mb-3" style={{ marginTop: "10rem" }}>
        <h4 className="mt-2 mb-4 m-bold">Actualización de Versión</h4>

        <p>
          <span className="op-text">Versión actual </span>
          {global.appVersion}
        </p>
        <p>
          {" "}
          Presione el botón para actualizar la aplicación a la última versión
          disponible.
        </p>

        <div>
          <button className="btn btn-info mt-3" onClick={actualizar}>
            Actualizar
          </button>
        </div>
        <div style={{ height: "30px" }}></div>
      </div>
    </Animated>
  );
};

export default ActualizarVersion;
