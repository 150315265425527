import React from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { estilosProv } from "./configProvincias";
import $ from "jquery";
import MovimientosIcon from "./img/movimientosIcon";

const SideBarMenu = (props) => {
  const codOrganizacion = process.env.REACT_APP_COD_ORGANIZACION;
  const colorFondo = estilosProv[codOrganizacion].fondo;
  const colorFont = estilosProv[codOrganizacion].fontColor;
  const colorBtnoJuegos = estilosProv[codOrganizacion].secundaryColor;

  return (
    <DivFondo aplicarGradiente={true} color={colorFondo}>
      {props.logueado === true && (
        <div>
          <div>
            <DivIcono onClick={() => props.volverAPlataforma()}>
              <Link
                exact={"true"}
                activeclassname="is-active"
                to="/home"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Inicio"
              >
                <div className="my-icon">
                  <ColorIcono
                    colorFont={colorFont}
                    className="my-icon iconify"
                    data-icon="bytesize:home"
                    data-inline="false"
                    data-width="23px"
                  ></ColorIcono>
                </div>
              </Link>
            </DivIcono>

            <DivIcono>
              <Link
                to="/consultaBilletera"
                onClick={() => {
                  props.volverAPlataforma();
                  props.consultarBilletera();
                }}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Movimientos"
              >
                <MovimientosIcon color={colorFont} width="35px" height="35px" />
              </Link>
            </DivIcono>

            <DivBtnNewGame
              className="BtnNewGame"
              data-toggle="modal"
              data-target="#newGameModalDesktop"
              id="BtnNewGameDesktop123"
            >
              <div
                className="my-icon"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Juegos"
              >
                <SpanBtnNewGame
                  colorFont={colorBtnoJuegos}
                  codOrganizacion={process.env.REACT_APP_COD_ORGANIZACION}
                  className="my-icon iconify"
                  data-icon="fluent:lottery-24-regular"
                  data-inline="false"
                  data-width="40px"
                ></SpanBtnNewGame>
              </div>
            </DivBtnNewGame>

            <DivIcono onClick={() => props.volverAPlataforma()}>
              <Link
                to={
                  process.env.REACT_APP_ENABLE_INGRESO_DINERO
                    ? "/identificadorQRCliente/0"
                    : "/identificadorQRCliente/"
                }
                data-toggle="tooltip"
                data-placement="bottom"
                title={"Billetera"}
              >
                <div className="my-icon">
                  <ColorIcono
                    colorFont={colorFont}
                    className="my-icon iconify"
                    data-icon="ion:wallet-outline"
                    data-inline="false"
                    data-width="25px"
                  ></ColorIcono>
                </div>
              </Link>
            </DivIcono>

            <DivIcono onClick={() => props.volverAPlataforma()}>
              <Link to="/misDatosPersonales">
                <div
                  className="my-icon"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Usuario"
                >
                  <ColorIcono
                    colorFont={colorFont}
                    className="my-icon iconify"
                    data-icon="bi:person"
                    data-inline="false"
                    data-flip="horizontal"
                    data-width="25px"
                  ></ColorIcono>
                </div>
              </Link>
            </DivIcono>
          </div>
        </div>
      )}
    </DivFondo>
  );
};

export default SideBarMenu;

const DivFondo = styled.div`
  background-color: ${(props) => props.color};
  ${(props) =>
    props.aplicarGradiente ? "box-shadow: 2px 3px 9px 1px #858f8a" : ""};
  width: 4rem;
  position: absolute;
  top: 30vh;
  left: 0;
  z-index: 2 !important;
  border-bottom-right-radius: 2em;
  border-top-right-radius: 2em;
`;

const efectoLogo = keyframes`
    0%{
      opacity: 0.1;
    }
    100% {
      scale: 1.5;
      opacity: 1;
    }
`;

const DivIcono = styled.div`
  font-size: small;
  margin: 1rem 0rem 1rem 0rem;
  text-align: center;
`;

const ColorIcono = styled.span`
  color: ${(props) => props.colorFont};
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.5);
  }
`;

const ColorTextIcono = styled.span`
  color: ${(props) => props.colorFont};
`;

const SpanBtnNewGame = styled.span`
  color: ${(props) =>
    ["42", "12"].includes(props.codOrganizacion) ? "black" : "white"};
  cursor: pointer;
  background-color: "white" ${(props) => props.colorFont};
  border-radius: 100%;
  width: 3rem;
  height: auto;
`;

const DivBtnNewGame = styled.div`
  color: white;
  text-align: center;
  cursor: pointer;
`;
