import React from "react";

class JuegoResponsable extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="p-1 mx-auto">
        <h5 className="text-center mb-3">
          RECOMENDACIONES PARA LOS JUEGOS DE AZAR Y ON-LINE
        </h5>

        <p className="text-small text-justify">
          Juga para divertirte, sin pensar en ganar dinero o para evadirte de
          tus problemas.
          <br></br>
          Tus obligaciones familiares, laborales o estudios son tu prioridad.
          <br></br>
          En el juego se puede tener suerte y ganar, pero considera que también
          puedes perder.
          <br></br>
          Las pérdidas son irreversibles.
          <br></br>
          Te recomendamos que establezcas de antemano el límite de dinero y
          tiempo
          <br></br>
          Si necesitas ayuda o{" "}
          {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
            ? "autolimitarte"
            : "autoexcluirte"}{" "}
          comunícate con el Departamento de Juego Responsable:{" "}
          <a
            className="helpLink"
            href="mailto:juego.responsable@ijan-neuquen.com"
          >
            juego.responsable@ijan-neuquen.com
          </a>
          <br></br>
          Para más información click{" "}
          <a
            className="helpLink"
            href="https://ijan-neuquen.com/juego_responsable.php"
            target="_blank"
            rel="noreferrer"
          >
            aquí
          </a>
          .
        </p>
      </div>
    );
  }
}

export default JuegoResponsable;
