import React from "react";

const OptionsProvincias = ({ provincias }) => {
  return provincias.map((provincia) => (
    <option key={provincia.codigo} value={provincia.codigo}>
      {provincia.descripcion}
    </option>
  ));
};

export default OptionsProvincias;
