import React from "react";
import ReactHtmlParser from "react-html-parser";

const ModalTerminosCondiciones = ({
  fetching,
  tyc,
  TerminosCondicionesTemplate,
}) => {
  return (
    <div
      style={
        navigator.vendor.toLocaleLowerCase().includes("apple")
          ? { overflow: "auto", top: "14vh", height: "72vh" }
          : { position: "fixed", top: "0", bottom: "0", overflowY: "hidden" }
      }
      className="modal fade pb-5"
      id="terminosModal"
      role="dialog"
      aria-labelledby="terminosModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div
            className="modal-header bg-light"
            style={{ position: "absolute", width: "100%", zIndex: "3" }}
          >
            <h5 className="modal-title" id="terminosModalLabel">
              Terminos y condiciones
            </h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div
            className="modal-body text-center mb-2"
            style={{ marginTop: "16%", height: "90vh", overflowY: "scroll" }}
          >
            {!fetching && tyc === null && <TerminosCondicionesTemplate />}
            {!fetching && tyc != null && ReactHtmlParser(tyc)}
            {navigator.vendor.toLocaleLowerCase().includes("apple") && (
              <button
                type="button"
                className="btn nextBtn text-light btn-success"
                data-dismiss="modal"
                aria-label="Close"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                Aceptar y Cerrar
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalTerminosCondiciones;
